<template>
  <div class="cont">
    <Spin v-if="queryFactoryLoading" fix></Spin>

    <router-link to="/company-profile/factories" class="back">
      <Icon type="ios-arrow-back" />
      <span>Go back to Factories</span>
    </router-link>

    <div class="content">
      <div class="titleWrap">
        <h3 class="title">{{ $route.query.fname || create.factory_name }}</h3>
        <router-link
          v-if="role === 'exhibitor'"
          class="edit"
          :to="`/factories/${create.id}/edit?fname=${create.factory_name}`"
        >
          <Button type="primary" icon="md-create" ghost
            >Edit Factory Info</Button
          >
        </router-link>
      </div>
      <div class="wrap">
        <div class="info">
          <div class="logo">
            <img alt="" :src="create.avatar.url" v-if="create.avatar" />
          </div>
          <ul class="lists">
            <li>
              <p class="tit">FACTORY NAME</p>
              <p class="name">{{ create.factory_name }}</p>
            </li>
            <li>
              <p class="tit">CITY</p>
              <p class="name">{{ create.city }}</p>
            </li>
            <li>
              <p class="tit">STATE / PROVINCE</p>
              <p class="name">{{ create.state }}</p>
            </li>
            <li>
              <p class="tit">COUNTRY</p>
              <p class="name">
                {{ create.country_info && create.country_info.name }}
              </p>
            </li>
          </ul>
        </div>
        <div class="desc">
          <h4>Basic Info</h4>
          <div class="link">
            <p class="tip">This factory page belongs to exhibitor:</p>
            <router-link :to="`/company/${company.id}`">
              <div class="belong">
                <img alt="" :src="company.avatar.url" v-if="company.avatar" />
                <strong>{{ company.company_name }}</strong>
              </div>
            </router-link>
          </div>

          <template v-if="create.description">
            <h5>FACTORY DESCRIPTION</h5>
            <div class="descCont" v-html="create.description"></div>
          </template>

          <h5 v-if="create.warehouses && create.warehouses.length">
            WAREHOUSE LOCATIONS
          </h5>
          <ul class="houseLists">
            <li v-for="(item, index) in create.warehouses" :key="index">
              <p class="tit">WAREHOUSE {{ index + 1 }}</p>
              <p class="name">
                {{ item.city }},{{ item.state }},{{ item.country_info.name }}
              </p>
            </li>
          </ul>

          <h4
            style="margin: 10px 0 20px"
            v-if="create.featured_products && create.featured_products.length"
          >
            Featured Products
          </h4>
          <Row type="flex" justify="start" :gutter="16">
            <i-col :key="item.id" v-for="item in productsFeatured" span="8">
              <div class="itemProduct">
                <router-link :to="`/products/${item.id}`">
                  <div class="img-box">
                    <img class="avatar" :src="item.images[0].url" />
                  </div>
                </router-link>

                <div class="name">
                  <router-link :to="`/products/${item.id}`">
                    <div class="product-name">
                      {{ item.marketing_name }}
                    </div></router-link
                  >

                  <router-link :to="`/products/${item.id}`">
                    <div class="product-type">
                      {{ item.product_type }}
                    </div></router-link
                  >
                </div>

                <div class="tags">
                  <div
                    v-if="!item.tags || (item.tags && item.tags.length === 0)"
                  >
                    No tags
                  </div>

                  <div
                    class="tag"
                    :style="{ background: tag.color }"
                    v-for="tag in item.tags"
                    :key="tag.id"
                  >
                    {{ tag.name }}
                  </div>
                </div>
              </div>
            </i-col>
          </Row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api";
import { mapState } from "vuex";
const { _editFactory, _country, _queryDocumentList, _queryFactory } = api;

export default {
  computed: {
    ...mapState("user", ["role"])
  },
  created() {
    const id = this.$route.params.id;
    this.id = id;

    this.queryFactoryLoading = true;
    _queryFactory({ id })
      .then(response => {
        const {
          data: {
            address,
            city,
            country,
            document,
            factory_name,
            fda_number,
            phone,
            zip_code,
            state
          }
        } = response;

        this.create = response.data;
        this.create.description = response.data.description || "No description";
        this.company = response.data.company;
        this.productsFeatured = response.data.featured_products;

        this.create.factory_name = factory_name;
        this.create.fda_number = fda_number;
        this.create.phone = phone;
        this.create.zip_code = zip_code;
        this.create.address = address;
        this.create.city = city;
        this.create.state = state;
        this.create.country = country;
        this.create.document = document.map(item => item.id);
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        this.queryFactoryLoading = false;
      });

    _country()
      .then(response => {
        const { data } = response;
        this.countryList = data;
      })
      .catch(error => {
        console.log(error);
      });

    _queryDocumentList({
      limit: 999,
      type: "Factory Certification"
    })
      .then(response => {
        const {
          data: { data }
        } = response;

        this.documentList = data;
      })
      .catch(error => {
        console.log(error);
      });
  },
  data() {
    return {
      queryFactoryLoading: false,
      required: [
        {
          required: true,
          message: "Please fill"
        }
      ],

      documentList: [],
      countryList: [],
      createLoading: false,
      create: {
        factory_name: null,
        phone: null,
        fda_number: null,
        zip_code: null,
        address: null,
        city: null,
        state: null,
        country: null,
        document: []
      },
      company: {},
      productsFeatured: []
    };
  },

  methods: {
    createSubmit() {
      this.$refs.create.validate(valid => {
        if (valid) {
          this.createLoading = true;
          _editFactory({
            id: this.id,
            ...this.create
          })
            .then(() => {
              this.$Message.success("success");
            })
            .catch(({ message }) => {
              this.$Message.error(message);
            })
            .finally(() => {
              this.createLoading = false;
            });
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.back {
  color: @primary-color;
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 14px 24px;
  border-bottom: 1px solid #ccc;
  font-weight: bold;
  background: #fff;
}
.cont {
  background: #fff;
  position: relative;
  .content {
    .titleWrap {
      position: relative;
      .title {
        color: #000;
        font-size: 20px;
        text-align: center;
        padding: 25px 0;
      }
      .edit {
        position: absolute;
        top: 25px;
        right: 20px;
      }
    }
    .wrap {
      display: flex;
      padding: 0 20px 30px;

      .info {
        flex: 0 0 30%;
        min-height: 350px;
        border: 1px solid #dfdfdf;
        border-radius: 8px;
        margin-right: 20px;
        padding: 15px;
        .logo {
          width: 100%;
          text-align: center;
          img {
            // width: 100%;
            height: 180px;
            border-radius: 6px;
          }
        }
        .lists {
          list-style: none;
          margin-top: 10px;
          font-size: 12px;
          li {
            margin-bottom: 15px;
          }
          .tit {
            color: #888;
            padding: 4px 0;
          }
          .name {
            color: #333;
            padding: 4px;
            font-size: 13px;
            border-bottom: 1px solid #ddd;
            background-color: #f9f9f9;
          }
        }
      }
      .desc {
        flex: 1;
        border: 1px solid #dfdfdf;
        border-radius: 8px;
        padding: 15px;
        h4 {
          font-size: 15px;
          color: #555;
          padding-bottom: 10px;
          border-bottom: 1px solid #ddd;
        }
        .link {
          width: 260px;
          font-size: 12px;
          padding: 10px;
          background-color: #fdfaf7;
          margin-top: 20px;
          .tip {
            color: #444;
            padding: 6px 0;
          }
          .belong {
            color: @primary-color;
            padding-bottom: 5px;
            img {
              width: 30px;
              border-radius: 50%;
              border: 1px solid #ddd;
              vertical-align: middle;
            }
            strong {
              vertical-align: middle;
              padding-left: 6px;
            }
          }
        }
        h5 {
          color: #888;
          font-weight: normal;
          padding: 25px 0 10px;
        }
        .descCont {
          color: #333;
          padding: 4px;
          border-bottom: 1px solid #ddd;
          background-color: #f9f9f9;
        }
        .houseLists {
          display: flex;
          list-style: none;
          font-size: 12px;
          flex-wrap: wrap;
          li {
            flex: 0 0 48%;
            border: 1px solid #ddd;
            border-radius: 4px;
            padding: 10px 15px 15px;
            margin-bottom: 20px;
            &:nth-child(n) {
              margin-right: 2%;
            }
          }
          .tit {
            color: #888;
            padding: 4px 0;
          }
          .name {
            color: #333;
            padding: 4px;
            font-size: 13px;
            border-bottom: 1px solid #ddd;
            background-color: #f9f9f9;
          }
        }
        .itemProduct {
          margin-bottom: 20px;
          border: 1px solid #ccc;
          background: #fff;
          box-shadow: 2px 4px 6px rgba(51, 51, 51, 0.149019607843137);
          border-radius: 5px;
          .img-box {
            width: 100%;
            height: 0px;
            position: relative;
            padding-bottom: 56.25%;
            overflow: hidden;
            border-bottom: 1px solid #ccc;
            background: #eee;
            .avatar {
              display: block;
              width: 100%;
            }
          }

          .name {
            padding: 10px;
            min-height: 80px;
            border-bottom: 1px solid #ccc;
            color: #666;
            .product-name {
              font-size: 16px;
              color: #333;
              font-weight: bold;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            .product-type {
              font-size: 13px;
              color: #333;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
          .tags {
            padding: 10px;
            height: 50px;
            display: flex;
            align-items: center;
            overflow: hidden;
            .tag {
              color: #fff;
              border-radius: 3px;
              font-size: 12px;
              margin-right: 4px;
              height: 25px;
              display: flex;
              align-items: center;
              padding: 0 5px;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}
</style>
